<h3 class="text-center">{{langStrings['personalDetails']}}</h3>
<hr>
<div class="row">
    <div class="col text-end">
        {{langStrings['firstName']}}:<br>
        {{langStrings['lastName']}}:<br>
        {{langStrings['fatherName']}}:<br>
        {{langStrings['placeOfBirth']}}:<br>
        {{langStrings['dateOfBirth']}}:<br>
        {{langStrings['socialID']}}:<br>
        {{langStrings['telephone']}}:<br>
    </div>
    <div class="col text-start fw-bold">
        Jakub<br>
        Papuga<br>
        Jan<br>
        Warszawa<br>
        01.01.2000<br>
        987654321<br>
        0700887877<br>
    </div>
</div>


<h3 class="text-center mt-3">{{langStrings['address']}}</h3>
<hr>
<div class="row">
    <div class="col text-end">
        {{langStrings['street']}}:<br>
        {{langStrings['house']}}:<br>
        {{langStrings['flat']}}:<br>
        {{langStrings['city']}}:<br>
        {{langStrings['region']}}:<br>
        {{langStrings['postalCode']}}:<br>
    </div>
    <div class="col text-start fw-bold">
        Długa<br>
        1<br>
        1<br>
        Warszawa<br>
        Mazowieckie<br>
        00-000<br>
    </div>
</div>