import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-study-programmes',
  templateUrl: './study-programmes.component.html',
  styleUrls: ['./study-programmes.component.css']
})
export class StudyProgrammesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
