import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router'
import { filter } from "rxjs/operators";
import { LanguageService } from '../services/language.service';
import translation from '../translation/navbar.json'

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {
  public langStrings;
  public isHome = false;
  public isMail = false;
  public isWikamp = false;
  path: string = '';

  constructor(private router: Router, private langService: LanguageService) { }

  ngOnInit(): void {
    this.getPath();
    this.langStrings = translation[this.langService.getLang()];
  }

  setActiveButton(): void {
    if (this.path === "/mail") {
      this.isMail = true;
    } else if (this.path === "/wikamp") {
      this.isWikamp = true;
    } else {
      this.isHome = true;
    }
  }

  getPath(): void {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(event => {
      this.path = event["urlAfterRedirects"];
      this.setActiveButton();
    });
  }

  setLang(lang: string) {
    this.langService.setLang(lang);
    location.reload();
  }

}
