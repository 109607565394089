import { Component, OnInit } from '@angular/core';
import { LanguageService } from '../services/language.service';
import translation from '../translation/medical-examinations.json'

@Component({
  selector: 'app-medical-examinations',
  templateUrl: './medical-examinations.component.html',
  styleUrls: ['./medical-examinations.component.css']
})
export class MedicalExaminationsComponent implements OnInit {
  public langStrings;

  constructor(private langService: LanguageService) { }

  ngOnInit(): void {
    this.langStrings = translation[this.langService.getLang()];
  }

}
