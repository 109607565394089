import { Component, OnInit } from '@angular/core';
import { LanguageService } from '../services/language.service';
import translation from '../translation/webdziekanat.json'

@Component({
  selector: 'app-webdziekanat',
  templateUrl: './webdziekanat.component.html',
  styleUrls: ['./webdziekanat.component.css']
})
export class WebdziekanatComponent implements OnInit {
  public langStrings;

  constructor(private langService: LanguageService) { }

  ngOnInit(): void {
    this.langStrings = translation[this.langService.getLang()];
  }

}
