<div class="row d-flex justify-content-center">
    <form class="col-2">
        <!-- <input type="search" class="form-control" placeholder="Search..."> -->
        <select class="form-select">
            <option>{{langStrings['semester']}} 1</option>
            <option>{{langStrings['semester']}} 2</option>
            <option>{{langStrings['semester']}} 3</option>
            <option>{{langStrings['semester']}} 4</option>
            <option>{{langStrings['semester']}} 5</option>
        </select>
    </form>
</div>

<table class="table table-striped">
    <thead>
      <tr>
        <th scope="col">{{langStrings['code']}}</th>
        <th scope="col">{{langStrings['name']}}</th>
        <th scope="col">{{langStrings['lecturer']}}</th>
        <th scope="col">{{langStrings['ects']}}</th>
        <th scope="col">{{langStrings['grade']}}</th>
        <th scope="col">{{langStrings['paid']}}</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <th scope="row">09 821015 00</th>
        <td>Civic Knowledge and Engagement 4</td>
        <td>mgr Iwona Wróblewska</td>
        <td>1</td>
        <td>-</td>
        <td><i class="bi bi-x-lg text-danger fw-bold"></i></td>
      </tr>
      <tr>
        <th scope="row">02 636340 00</th>
        <td>Computer Aided Design</td>
        <td>dr inż. Witold Kubiak</td>
        <td>3</td>
        <td>-</td>
        <td><i class="bi bi-x-lg text-danger fw-bold"></i></td>
      </tr>
      <tr>
        <th scope="row">02 866131 00</th>
        <td>Diploma Proseminar</td>
        <td>dr hab. inż. Lidia Jackowska-Strumiłło</td>
        <td>1</td>
        <td>-</td>
        <td><i class="bi bi-x-lg text-danger fw-bold"></i></td>
      </tr>
      <tr>
        <th scope="row">02 526421 00</th>
        <td>GUI-programming</td>
        <td>dr inż. Jacek Nowakowski</td>
        <td>3</td>
        <td>-</td>
        <td><i class="bi bi-x-lg text-danger fw-bold"></i></td>
      </tr>
      <tr>
        <th scope="row">02 646214 00</th>
        <td>Internet of Things</td>
        <td>dr inż. Krzysztof Lichy</td>
        <td>5</td>
        <td>-</td>
        <td><i class="bi bi-x-lg text-danger fw-bold"></i></td>
      </tr>
      <tr>
        <th scope="row">02 556163 00</th>
        <td>Operating Systems</td>
        <td>dr hab. inż. Krzysztof Grudzień</td>
        <td>5</td>
        <td>-</td>
        <td><i class="bi bi-x-lg text-danger fw-bold"></i></td>
      </tr>
      <tr>
        <th scope="row">02 966203 00</th>
        <td>Professional Design Project</td>
        <td>dr hab. Laurent Babout</td>
        <td>9</td>
        <td>-</td>
        <td><i class="bi bi-x-lg text-danger fw-bold"></i></td>
      </tr>
      <tr>
        <th scope="row">02 646215 00</th>
        <td>Virtual Reality</td>
        <td>dr inż. Dorota Kamińska</td>
        <td>3</td>
        <td>-</td>
        <td><i class="bi bi-x-lg text-danger fw-bold"></i></td>
      </tr>
    </tbody>
  </table>