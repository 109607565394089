<img class="img-fluid mb-2" src="assets/panPrezydent.png" style='max-height:300px'>
<figure>
    <blockquote class="blockquote">
      <p>"{{langStrings['president-quote-1']}}"</p>
    </blockquote>
    <figcaption class="blockquote-footer">
      Krzysztof Kononowicz, <cite title="Source Title">Studio Wyborcze TVJARD 2006</cite>
    </figcaption>
  </figure>
  <figure>
    <blockquote class="blockquote">
      <p>"{{langStrings['president-quote-2']}}"</p>
    </blockquote>
    <figcaption class="blockquote-footer">
      Krzysztof Kononowicz, <cite title="Source Title">Studio Wyborcze TVJARD 2006</cite>
    </figcaption>
  </figure>