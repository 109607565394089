import { Component, OnInit } from '@angular/core';
import { LanguageService } from '../services/language.service';
import translation from '../translation/personal-details.json'

@Component({
  selector: 'app-personal-details',
  templateUrl: './personal-details.component.html',
  styleUrls: ['./personal-details.component.css']
})
export class PersonalDetailsComponent implements OnInit {
  public langStrings;

  constructor(private langService: LanguageService) { }

  ngOnInit(): void {
    this.langStrings = translation[this.langService.getLang()];
  }

}
