import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/internal/operators/filter';
import { LanguageService } from '../services/language.service';
import translation from '../translation/tree-navigation.json'

@Component({
  selector: 'app-tree-navigation',
  templateUrl: './tree-navigation.component.html',
  styleUrls: ['./tree-navigation.component.css']
})
export class TreeNavigationComponent implements OnInit {
  public langStrings;
  public path: string = "";

  constructor(private router: Router, private langService: LanguageService) { }

  ngOnInit(): void {
    this.getPath()
    this.langStrings = translation[this.langService.getLang()];
  }

  getPath(): void {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(event => {
      this.path = event["urlAfterRedirects"];
    });
  }

}
