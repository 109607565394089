<i class="bi bi-check2-circle text-success display-1 my-0"></i>

<div class="text-success fw-bold">{{langStrings['valid-from']}} 2021/10 {{langStrings['valid-to']}} 2022/08</div>

<h3 class="text-center mt-5">{{langStrings['details']}}</h3>
<hr>
<h5>{{langStrings['rectors']}}</h5>
<div class="row">
    <div class="col text-end">
        {{langStrings['application-completed']}}:<br>
        {{langStrings['application-reviewed']}}:<br>
        {{langStrings['amount']}}:<br>
    </div>
    <div class="col text-start fw-bold">
        2021/09/22<br>
        2021/10/15 {{langStrings['by']}} mgr. Adrianna Sawicka<br>
        <strong>700PLN</strong><br>
    </div>
</div>

<div class="fst-italic mt-2">{{langStrings['fine-print']}}</div>

<h3 class="text-center mt-5">{{langStrings['other']}}</h3>
<hr>
<button class="btn btn-primary">{{langStrings['button-social']}}</button>