<i class="bi bi-check2-circle text-success display-1 my-0"></i>

<div class="text-success fw-bold">{{langStrings['valid-to']}}: 2024/11/04</div>

<h3 class="text-center mt-5">{{langStrings['history']}}</h3>
<hr>
<table class="table">
    <thead>
      <tr>
        <th class="fw-bold" scope="col">{{langStrings['number']}}</th>
        <th class="fw-bold" scope="col">{{langStrings['valid-to']}}</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <th scope="row">CKM/Lek/2619/2020/21/SID</th>
        <td>2024/11/04</td>
      </tr>
      <tr>
        <th scope="row">CKM/Lek/1931/2019/20/SID</th>
        <td>2021/08/23</td>
      </tr>
    </tbody>
  </table>