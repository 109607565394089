import { Component, OnInit } from '@angular/core';
import { LanguageService } from '../services/language.service';
import translation from '../translation/wikamp.json'

@Component({
  selector: 'app-wikamp',
  templateUrl: './wikamp.component.html',
  styleUrls: ['./wikamp.component.css']
})
export class WikampComponent implements OnInit {
  public langStrings;
  public redirectTimeSeconds = 2;
  public redirectURL = "https://edu.p.lodz.pl/";

  constructor(private langService: LanguageService) { }

  ngOnInit(): void {
    this.langStrings = translation[this.langService.getLang()];
  }

}
