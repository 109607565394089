<header class="d-flex flex-wrap align-items-center justify-content-center justify-content-md-between pb-2 mb-4 border-bottom">
    <a href="/" class="d-flex align-items-center col-md-3 mb-0 mb-md-0 text-dark text-decoration-none">
      <img height="100rem" src="assets/logoPL.svg">
      <h4 class="ms-2 mt-5">Politechnika Łódzka</h4>
    </a>
    
    <div class="col-12 mt-md-4 mt-2 text-center hidden-md d-block d-md-none">
      <button class="btn btn-primary me-4 fw-bold"><i class="bi bi-box-arrow-right fw-bold"></i></button>
      <a class="pe-pointer" (click)="setLang(langStrings['next-lang'])">
        <img class="rounded-circle" height="50rem" src="{{langStrings['next-lang-flag']}}">
      </a>
    </div>

    <ul class="nav nav-pills col-12 col-md-auto justify-content-center mt-2rem mb-md-0 m-1">
      <li class="nav-item mx-1"><a href="/" class="nav-link px-2" [ngClass]="{'active': isHome, 'link-dark': !isHome}">{{langStrings['button-home']}}</a></li>
      <li class="nav-item mx-1"><a href="/mail" class="nav-link px-2" [ngClass]="{'active': isMail, 'link-dark': !isMail}">{{langStrings['button-mail']}}</a></li>
      <li class="nav-item mx-1"><a href="/wikamp" class="nav-link px-2" [ngClass]="{'active': isWikamp, 'link-dark': !isWikamp}">{{langStrings['button-wikamp']}}</a></li>
    </ul>

    <div class="col-md-3 mt-md-4 mt-2 text-end hidden-md d-none d-md-block">
      <button class="btn btn-primary me-4 fw-bold"><i class="bi bi-box-arrow-right fw-bold"></i></button>
      <a class="pe-pointer" (click)="setLang(langStrings['next-lang'])">
        <img class="rounded-circle" height="50rem" src="{{langStrings['next-lang-flag']}}">
      </a>
    </div>
  </header>