import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CalendarComponent } from './calendar/calendar.component';
import { DocumentsComponent } from './documents/documents.component';
import { EvaluationSheetComponent } from './evaluation-sheet/evaluation-sheet.component';
import { FinancialSupportComponent } from './financial-support/financial-support.component';
import { HomePageComponent } from './home-page/home-page.component';
import { MailComponent } from './mail/mail.component';
import { MedicalExaminationsComponent } from './medical-examinations/medical-examinations.component';
import { PersonalDetailsComponent } from './personal-details/personal-details.component';
import { RegulationsComponent } from './regulations/regulations.component';
import { SettlementsComponent } from './settlements/settlements.component';
import { StudyProgrammesComponent } from './study-programmes/study-programmes.component';
import { WikampComponent } from './wikamp/wikamp.component';

const routes: Routes = [
  // { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: '', component: HomePageComponent},
  { path: 'personal-data', component: PersonalDetailsComponent},
  { path: 'settlements', component: SettlementsComponent},
  { path: 'evaluation-sheet', component: EvaluationSheetComponent},
  { path: 'documents', component: DocumentsComponent},
  { path: 'medical-examinations', component: MedicalExaminationsComponent},
  { path: 'financial-support', component: FinancialSupportComponent},
  { path: 'regulations', component: RegulationsComponent},
  { path: 'calendar', component: CalendarComponent},
  { path: 'study-programmes', component: StudyProgrammesComponent},
  { path: 'mail', component: MailComponent},
  { path: 'wikamp', component: WikampComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
