<h3 class="text-center">{{langStrings['information']}}</h3>
<hr>
{{langStrings['accountForPayment']}}: <h4 class="mb-0">04102000324590230364000000</h4>
<p class="text-muted">{{langStrings['paymentInformation']}} <br>
Centrum Kształcenia Międzynarodowego Politechniki Łódzkiej<br>
ul. Żwirki 36<br></p>

<p class="mb-1">{{langStrings['accountForPayout']}}:</p>
<div class="row mt-0">
    <div class="col col-3"></div>
    <div class="col col-6">
        <input class="form-control form-control-lg" [ngClass]="{'is-valid': accValid, 'is-invalid': !accValid}" [(ngModel)]="accNumber" (change)="checkAccNumber()" id="exampleInputEmail1">
    </div>
    <div class="col col-3"></div>
</div>
<button type="button" class="btn mt-2 btn-primary btn-sm">{{langStrings['save']}}</button>


<h3 class="text-center mt-3">{{langStrings['history']}}</h3>
<hr>
<table class="table">
    <thead>
      <tr>
        <th scope="col">{{langStrings['type']}}</th>
        <th scope="col">{{langStrings['date']}}</th>
        <th scope="col">{{langStrings['title']}}</th>
        <th scope="col">{{langStrings['amount']}}</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <th scope="row">{{langStrings['payout']}}</th>
        <td>2021-11-24</td>
        <td>Stypendium rektora</td>
        <td>700PLN</td>
      </tr>
      <tr>
        <th scope="row">{{langStrings['payout']}}</th>
        <td>2021-11-24</td>
        <td>Stypendium rektora</td>
        <td>700PLN</td>
      </tr>
      <tr>
        <th scope="row">{{langStrings['payment']}}</th>
        <td>2019-08-29</td>
        <td>Opłata za legitymację</td>
        <td>22PLN</td>
      </tr>
    </tbody>
  </table>