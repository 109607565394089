<div class="row d-flex justify-content-center">
    <div class="col-lg-3">
        <div class="card mb-3 rounded-5 shadow-sm">
            <div class="bg-primary card-header text-center py-1 text-white d-flex justify-content-between">
            <!-- <div class="card-header text-center py-1 text-white bg-primary d-flex justify-content-between"> -->
            </div>
            <div class="card-body d-flex align-items-center justify-content-center" style="height: 12rem">
                <a href="/medical-examinations" class="text-decoration-none fs-5 p-3"><i class="bi bi-bandaid"></i><br>{{langStrings['medical-examinations']}}</a>
            </div>
        </div>
    </div>
    <div class="col-lg-3">
        <div class="card mb-3 rounded-5 shadow-sm">
            <div class="bg-primary card-header text-center py-1 text-white d-flex justify-content-between">
            <!-- <div class="card-header text-center py-1 text-white bg-primary d-flex justify-content-between"> -->
            </div>
            <div class="card-body d-flex align-items-center justify-content-center" style="height: 12rem">
                <a href="/financial-support" class="text-decoration-none fs-5 p-3"><i class="bi bi-piggy-bank"></i><br>{{langStrings['financial-support']}}</a>
            </div>
        </div>
    </div>
    <div class="col-lg-3">
        <div class="card mb-3 rounded-5 shadow-sm">
            <div class="bg-primary card-header text-center py-1 text-white d-flex justify-content-between">
            <!-- <div class="card-header text-center py-1 text-white bg-primary d-flex justify-content-between"> -->
            </div>
            <div class="card-body d-flex align-items-center justify-content-center" style="height: 12rem">
                <a href="/regulations" class="text-decoration-none fs-5 p-3"><i class="bi bi-info-circle"></i><br>{{langStrings['regulations']}}</a>
            </div>
        </div>
    </div>
</div>