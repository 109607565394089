import { Component, OnInit } from '@angular/core';
import { LanguageService } from '../services/language.service';
import translation from '../translation/evaluation-sheet.json'

@Component({
  selector: 'app-evaluation-sheet',
  templateUrl: './evaluation-sheet.component.html',
  styleUrls: ['./evaluation-sheet.component.css']
})
export class EvaluationSheetComponent implements OnInit {
  public langStrings;

  constructor(private langService: LanguageService) { }

  ngOnInit(): void {
    this.langStrings = translation[this.langService.getLang()];
  }

}
