import { Component, OnInit } from '@angular/core';
import { LanguageService } from '../services/language.service';
import translation from '../translation/financial-support.json'

@Component({
  selector: 'app-financial-support',
  templateUrl: './financial-support.component.html',
  styleUrls: ['./financial-support.component.css']
})
export class FinancialSupportComponent implements OnInit {
  public langStrings;

  constructor(private langService: LanguageService) { }

  ngOnInit(): void {
    this.langStrings = translation[this.langService.getLang()];
  }

}
