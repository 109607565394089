import { Component, OnInit } from '@angular/core';
import { LanguageService } from '../services/language.service';
import translation from '../translation/mail.json'

@Component({
  selector: 'app-mail',
  templateUrl: './mail.component.html',
  styleUrls: ['./mail.component.css']
})
export class MailComponent implements OnInit {
  public langStrings;
  public redirectTimeSeconds = 2;
  public redirectURL = "http://poczta.p.lodz.pl/";

  constructor(private langService: LanguageService) { }

  ngOnInit(): void {
    this.langStrings = translation[this.langService.getLang()];
  }

}
