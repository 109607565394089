import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from "@angular/forms";
import { FullCalendarModule } from "@fullcalendar/angular";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavbarComponent } from './navbar/navbar.component';
import { WikampComponent } from './wikamp/wikamp.component';
import { MailComponent } from './mail/mail.component';
import { WebdziekanatComponent } from './webdziekanat/webdziekanat.component';
import { TreeNavigationComponent } from './tree-navigation/tree-navigation.component';
import { HomePageComponent } from './home-page/home-page.component';
import { PersonalDetailsComponent } from './personal-details/personal-details.component';
import { SettlementsComponent } from './settlements/settlements.component';
import { EvaluationSheetComponent } from './evaluation-sheet/evaluation-sheet.component';
import { DocumentsComponent } from './documents/documents.component';
import { MedicalExaminationsComponent } from './medical-examinations/medical-examinations.component';
import { FinancialSupportComponent } from './financial-support/financial-support.component';
import { RegulationsComponent } from './regulations/regulations.component';
import { CalendarComponent } from './calendar/calendar.component';
import { StudyProgrammesComponent } from './study-programmes/study-programmes.component';

FullCalendarModule.registerPlugins([
  dayGridPlugin,
  interactionPlugin
]);

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    WikampComponent,
    MailComponent,
    WebdziekanatComponent,
    TreeNavigationComponent,
    HomePageComponent,
    PersonalDetailsComponent,
    SettlementsComponent,
    EvaluationSheetComponent,
    DocumentsComponent,
    MedicalExaminationsComponent,
    FinancialSupportComponent,
    RegulationsComponent,
    CalendarComponent,
    StudyProgrammesComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    FullCalendarModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
