import { Component, OnInit } from '@angular/core';
import { LanguageService } from '../services/language.service';
import translation from '../translation/settlements.json'

@Component({
  selector: 'app-settlements',
  templateUrl: './settlements.component.html',
  styleUrls: ['./settlements.component.css']
})
export class SettlementsComponent implements OnInit {
  public langStrings;
  public accNumber: string = "93109028350000000132608517";
  public accValid: boolean = true;

  constructor(private langService: LanguageService) { }

  ngOnInit(): void {
    this.langStrings = translation[this.langService.getLang()];
  }

  //check if number is 26 digits long
  checkAccNumber() {
    if (this.accNumber.length == 26) {
      this.accValid = true;
      return true;
    }
    this.accValid = false;
    return false;
  }

}
