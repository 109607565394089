<div class="row">
    <div class="col-lg-3">
        <div class="card mb-3 rounded-5 shadow-sm">
            <div class="bg-primary card-header text-center py-1 text-white d-flex justify-content-between">
            <!-- <div class="card-header text-center py-1 text-white bg-primary d-flex justify-content-between"> -->
            </div>
            <div class="card-body">
                <app-tree-navigation></app-tree-navigation>
            </div>
        </div>
    </div>
    <div class="col-lg-9">
        <div class="card mb-3 rounded-5 shadow-sm">
            <div class="bg-primary card-header text-center py-1 text-white d-flex justify-content-between">
            <!-- <div class="card-header text-center py-1 text-white bg-primary d-flex justify-content-between"> -->
            </div>
            <div class="card-body text-center">
              <router-outlet></router-outlet>
            </div>
        </div>
    </div>
</div>