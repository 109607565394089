<a class="text-decoration-none" [ngClass]="{'fw-bold': path == '/personal-data'}" href="/personal-data"><i class="bi bi-award"></i> {{langStrings['personal-data']}}</a><br>
<div class="ms-3">
    <a class="text-decoration-none" [ngClass]="{'fw-bold': path == '/settlements'}" href="/settlements"><i class="bi bi-wallet2"></i> {{langStrings['settlements']}}</a><br>
    <a class="text-decoration-none" [ngClass]="{'fw-bold': path == '/evaluation-sheet'}" href="/evaluation-sheet"><i class="bi bi-trophy"></i> {{langStrings['evaluation-sheet']}}</a><br>
    <a class="text-decoration-none" [ngClass]="{'fw-bold': path == '/documents'}" href="/documents"><i class="bi bi-file-earmark-text"></i> {{langStrings['documents']}}</a><br>
    <div class="ms-3">
        <a class="text-decoration-none" [ngClass]="{'fw-bold': path == '/medical-examinations'}" href="/medical-examinations"><i class="bi bi-bandaid"></i> {{langStrings['medical-examinations']}}</a><br>
        <a class="text-decoration-none" [ngClass]="{'fw-bold': path == '/financial-support'}" href="/financial-support"><i class="bi bi-piggy-bank"></i> {{langStrings['financial-support']}}</a><br>
        <a class="text-decoration-none" [ngClass]="{'fw-bold': path == '/regulations'}" href="/regulations"><i class="bi bi-info-circle"></i> {{langStrings['regulations']}}</a><br>
    </div>
</div>
<a class="text-decoration-none" [ngClass]="{'fw-bold': path == '/calendar'}" href="/calendar"><i class="bi bi-calendar3"></i> {{langStrings['calendar']}}</a><br>
<a class="text-decoration-none" [ngClass]="{'fw-bold': path == '/study-programmes'}" href="/study-programmes"><i class="bi bi-easel"></i> {{langStrings['study-programmes']}}</a><br>