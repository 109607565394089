import { Component, OnInit } from '@angular/core';
import { CalendarOptions } from "@fullcalendar/angular";
import { LanguageService } from '../services/language.service';
import translation from '../translation/calendar.json'

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.css']
})
export class CalendarComponent implements OnInit {
  public langStrings;
  calendarOptions: CalendarOptions = {}

  

  constructor(private langService: LanguageService) { }

  ngOnInit(): void {
    this.langStrings = translation[this.langService.getLang()];
    this.setCalendarOptions();
  }

  setCalendarOptions() {

    this.calendarOptions = {
      initialView: 'dayGridMonth',
      locale: this.langStrings['calendar-lang'],
      events: [
        { title: this.langStrings['classes'], start: "2021-10-01", end: "2021-12-23", color: "#AEAFB3" },
        { title: this.langStrings['classes'], start: "2022-01-03", end: "2022-02-01", color: "#AEAFB3" },
        { title: this.langStrings['christmas-holiday'], start: "2021-12-23", end: "2022-01-03", color: "green" },
        { title: this.langStrings['session-1-winter'], start: "2022-02-01", end: "2022-02-14", color: "#8B0304" },
        { title: this.langStrings['wainter-holiday'], start: "2022-02-14", end: "2022-02-21", color: "green" },
        { title: this.langStrings['session-2-winter'], start: "2022-02-21", end: "2022-02-28", color: "#8B0304" },
        { title: this.langStrings['day-off'], start: "2021-11-12", end: "2021-11-13", color: "green" },
        { title: this.langStrings['day-off'], start: "2022-01-07", end: "2022-01-08", color: "green" },
        { title: this.langStrings['thursday-schedule'], start: "2021-11-09", end: "2021-11-10", color: "purple"},
        { title: this.langStrings['friday-schedule'], start: "2021-11-10", end: "2021-11-11", color: "purple"},
        { title: this.langStrings['thursday-schedule'], start: "2022-01-03", end: "2022-01-04", color: "purple"}
    ]
    }

  }

}
